import * as components from "../components";
import * as pages from "../pages";

export const map = {
  components: {
    fixedNavigation: components.FixedNavigation,
    heroFormation: components.HeroFormation,
    coloredBanner: components.ColoredBanner,
    richContentLogo: components.RichContentLogo,
    textImageList: components.TextImageList,
    keysPoints: components.KeysPoints,
    introProgram: components.IntroProgram,
    imageFull: components.ImageFull,
    cardEvent: components.CardEvent,
    customCursor: components.CustomCursor,
    singleListing: components.SingleListing,
    cardMots: components.CardMots,
    relatedMots: components.RelatedMots,
    listing: components.Listing,
    largeVideo: components.LargeVideo,
    bannerInscription: components.BannerInscription,
    sliderImageText: components.SliderImageText,
    sliderImages: components.SliderImages,
    sliderNavigation: components.SliderNavigation,
    fAQ: components.FAQ,
    dropdown: components.dropdown,
    filterBar: components.FilterBar,
    nextEvents: components.NextEvents,
    pushParcours: components.PushParcours,
    cardFormation: components.CardFormation,
    pushFormations: components.PushFormations,
    cardsCampus: components.CardsCampus,
    cardsAdresse: components.CardsAdresse,
    cardsObjectif: components.CardsObjectif,
    cardColoredCropped: components.CardColoredCropped,
    cardsTarif: components.CardsTarif,
    cardSmallCropped: components.CardSmallCropped,
    cardsIntervenant: components.CardsIntervenant,
    cardSimple: components.CardSimple,
    cardsDoubleColumn: components.CardsDoubleColumn,
    cardEncadrants: components.CardEncadrants,
    pushEncadrants: components.PushEncadrants,
    cardJobs: components.CardJobs,
    pushJobs: components.PushJobs,
    cardAnciens: components.CardAnciens,
    pushAnciens: components.PushAnciens,
    cPTInfosBar: components.CPTInfosBar,
    cardFilm: components.CardFilm,
    pushFilms: components.PushFilms,
    pushDoubleNews: components.PushDoubleNews,
    cardNews: components.CardNews,
    linkRounded: components.LinkRounded,
    pushTripleNews: components.PushTripleNews,
    socialShare: components.SocialShare,
    heroShape: components.HeroShape,
    heroSimple: components.HeroSimple,
    iframe: components.Iframe,
    smallPopin: components.SmallPopin,
    heroHome: components.HeroHome,
    dropdownLang: components.DropdownLang,
    circleLink: components.CircleLink,
    socialBar: components.SocialBar,
    linkRoundedArrow: components.LinkRoundedArrow,
    footer: components.Footer,
    header: components.Header,
  },
  pages: {
    index: {
      candidacy: pages.candidacy,
      brochure: pages.brochure,
    },
    formations: {
      index: pages.formations,
    },
    ancienlist: {
      index: pages.ancienlist,
    },
    event: {
      index: pages.event,
    },
    joblist: {
      index: pages.joblist,
    },
    filmlist: {
      index: pages.filmlist,
    },
    mots: {
      index: pages.motsIndex,
    },
    lexique: {
      index: pages.lexiqueIndex,
    },
    legals: {
      index: pages.legalsIndex,
    },
    campus: {
      index: pages.campusIndex,
    },
    jobs: {
      index: pages.jobsIndex,
    },
    anciens: {
      index: pages.anciensIndex,
    },
    films: {
      index: pages.filmsIndex,
    },
    news: {
      list: pages.newsList,
      index: pages.newsIndex,
    },
    homepage: {
      index: pages.homepageIndex,
    },
    contact: {
      index: pages.contactIndex,
    },
    flexible: {
      index: pages.flexibleIndex,
    },
    404: {
      index: pages.flexibleIndex,
    },
  },
};
