import Component from "../component";

export default class Footer extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDOM();
  }
  _retrieveDOM() {
    this.dom.links = this.dom.component.querySelectorAll(".link_hidden");
  }

  init() {
    if (this.dom.links) {
      Array.from(this.dom.links).map((link) => {
        const href = link.getAttribute("data-href");
        link.addEventListener("click", () => {
          window.location.href = href;
        });
      });
    }
  }
}
