import Component from "../component";

export default class CardEncadrants extends Component {
  _classes = {
    card_with_modal: "with_modal",
    card: ".card",
    modal: ".card_modal",
    button: ".modal_close",
  };

  _open = false;

  constructor(...args) {
    super(...args);
    this._retrieveDOM();
  }

  _retrieveDOM() {
    if (this.hasModal()) {
      this.card = this.dom.component.querySelector(this._classes.card);
      this.modal = this.dom.component.querySelector(this._classes.modal);
      this.button = this.dom.component.querySelector(this._classes.button);
    }
  }

  hasModal = () => {
    if (this.dom.component.classList.contains(this._classes.card_with_modal))
      return true;
    return false;
  };

  bind() {
    if (this.hasModal()) {
      this.card.addEventListener("click", () => {
        console.log("click modal");
        if (!this._open) this.modal.showModal();
        this._open = true;
      });

      this.button.addEventListener("click", () => {
        console.log("click close button");
        if (this._open) this.modal.close();
        this._open = false;
      });
    }
  }
}
