import Component from "../component";
import DropdownManager from "../../helpers/dropdown";
export default class DropdownLang extends Component {
  constructor(...args) {
    super(...args);
  }

  init() {
    super.init();

    // this.dropdown = new DropdownManager({
    // 	dom: this.dom.component,
    // });
  }
}
