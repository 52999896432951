import Component from "../component";
import Macy from "macy";
export default class CardsIntervenant extends Component {
  _classes = {
    button: ".load_more",
    cardsHidden: ".card_hidden",
    cards: ".card_intervenant",
  };

  constructor(...args) {
    super(...args);
    this.masonry = null;

    this._retrieveDOM();
    this.checkIfNext();
  }

  _retrieveDOM() {
    this.button = this.dom.component.querySelector(this._classes.button);
    this.cards = this.dom.component.querySelectorAll(this._classes.cards);
  }

  checkIfNext = () => {
    let cards = this.dom.component.querySelectorAll(this._classes.cardsHidden);
    if (cards.length <= 0) this.button.classList.add("button_hidden");
  };

  openExpand(el) {
    el.querySelector(".js-description").classList.toggle("open");
    el.querySelector(".js-more").classList.toggle("open");
  }

  bind() {
    super.bind();

    if (window.innerWidth > 768) {
      this.masonry = Macy({
        container: this.dom.component.querySelector(".card_wrapper"),
        trueOrder: true,
        waitForImages: false,
        margin: 24,
        columns: window.innerWidth < 1100 ? 2 : 3,
        breakAt: {
          1100: 3,
          768: 2,
          400: 1,
        },
      });
    }

    this.button.addEventListener("click", () => {
      let cards = this.dom.component.querySelectorAll(
        this._classes.cardsHidden,
      );

      const display = Array.from(cards).slice(0, 6);
      display.forEach((el) => el.classList.remove("card_hidden"));
      if (window.innerWidth > 768) {
        setTimeout(() => {
          this.masonry.recalculate(true);
        }, 50);
      }
      this.checkIfNext();
    });

    this.cards.forEach((el) => {
      const btnMore = el.querySelector(".js-more");
      if (btnMore) {
        btnMore.addEventListener("click", () => {
          this.openExpand(el);
          if (window.innerWidth > 768) {
            setTimeout(() => {
              this.masonry.recalculate(true);
            }, 50);
          }
        });
      }
    });
  }

  resize() {
    if (this.masonry && window.innerWidth < 768) this.masonry.remove();
  }
}
