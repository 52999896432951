import Swiper, { Navigation, Mousewheel, EffectFade } from "swiper";
import Component from "../component";

export default class SliderImageText extends Component {
  constructor(...args) {
    super(...args);
    this.retrieveDOM();
    this.initSwiper();
  }

  retrieveDOM() {
    this.dom.wrapper = this.dom.component.querySelector(".slider");
    this.dom.wrapperRef = this.dom.component.querySelector(".slider-content");
    this.dom.prevButton = this.dom.component.querySelector(".button.prev");
    this.dom.nextButton = this.dom.component.querySelector(".button.next");
  }

  initSwiper() {
    const swiper = new Swiper(this.dom.wrapper, {
      modules: [Navigation, Mousewheel, EffectFade],
      direction: "horizontal",
      mousewheel: { forceToAxis: true },
      navigation: {
        prevEl: this.dom.prevButton,
        nextEl: this.dom.nextButton,
      },
      speed: 600,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      slidesPerView: 1,
      spaceBetween: 0,
    });

    const swiperRef = new Swiper(this.dom.wrapperRef, {
      modules: [Navigation, Mousewheel, EffectFade],
      direction: "horizontal",
      mousewheel: { forceToAxis: true },
      navigation: {
        prevEl: this.dom.prevButton,
        nextEl: this.dom.nextButton,
      },
      speed: 600,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      slidesPerView: 1,
      spaceBetween: 0,
    });
  }
}
