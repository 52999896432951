import Component from "../component";
import Swiper, { Mousewheel } from "swiper";

export default class SliderImages extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.slide = this.dom.component.querySelectorAll(".swiper-slide");
    this.dom.slider = this.dom.component.querySelector(".js-slider");
  }

  init() {
    this.Slider = new Swiper(this.dom.slider, {
      modules: [Mousewheel],
      direction: "horizontal",
      slidesPerView: "auto",
      loop: this.dom.slide.length >= 6 ? true : false,
      mousewheel: { forceToAxis: true },
      freeMode: true,
    });
  }
  /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
