import Page from "../page";

export default class CampusIndex extends Page {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
    this._initTls();
  }

  /**
   * init() {}
   * bind() {}
   * unbind() {}
   * resize(width, height) {}
   * _destroy() {}
   */
}
