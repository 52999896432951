import Component from "../component";

export default class CardJobs extends Component {
  constructor(...args) {
    super(...args);
  }

  /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
