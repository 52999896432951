import Component from "../component";
import AsyncListManager, {
  AsyncListManagerEvent,
} from "../../helpers/async-list-manager.js";

export default class SingleListing extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();

    this.type = this.dom.component.dataset.type;
    this.filtersId = [];
  }

  _retrieveDOM() {
    this.dom.listCards = this.dom.component.querySelector(
      ".js-container-cards",
    );
    this.dom.loadMore = this.dom.component.querySelector(".js-load-more");
    this.dom.filters = this.dom.component.querySelectorAll(".js-filter-item");
    this.dom.btnFilters = this.dom.component.querySelector(".js-btn-filters");
    this.dom.closeFilters =
      this.dom.component.querySelectorAll(".js-close-filters");
    this.dom.popinFilters =
      this.dom.component.querySelector(".js-popin-filters");
    this.dom.resetBtn = this.dom.component.querySelector(".js-btn-reset");
    this.dom.searchBtn = this.dom.component.querySelector(".js-btn-search");
    this.dom.inputSearch = this.dom.component.querySelector(".js-input-search");
  }

  init() {
    //init load more
    this._ALM = new AsyncListManager(
      "sp_theme_posts_load_ajax",
      this.dom.loadMore,
    );

    //Event click filters
    this.dom.filters.forEach((filter) =>
      filter.addEventListener("click", () => this.manageFilters(filter)),
    );

    //Options for request load more
    this.opts = {
      isSearch: true,
      type: this.type,
      filters: this.filtersId,
    };

    this._ALM.setOpts(this.opts);

    //Event click load more
    if (this.dom.loadMore) {
      this.dom.loadMore.addEventListener(
        "click",
        this._clickLoadMore.bind(this),
      );
    }

    //Event click btn filters (only mobile)
    if (this.dom.btnFilters) {
      this.dom.btnFilters.addEventListener(
        "click",
        this._managePopin.bind(this),
      );
    }

    //Event click close filters (only mobile)
    if (this.dom.closeFilters) {
      for (let index = 0; index < this.dom.closeFilters.length; index++) {
        this.dom.closeFilters[index].addEventListener(
          "click",
          this._closePopin.bind(this),
        );
      }
    }

    //Event click btn search
    if (this.dom.searchBtn && this.dom.inputSearch) {
      this.dom.searchBtn.addEventListener(
        "click",
        this._searchProcess.bind(this),
      );
    }

    //Event click btn reset search
    if (this.dom.resetBtn && this.dom.inputSearch) {
      this.dom.resetBtn.addEventListener("click", this._resetSearch.bind(this));
    }

    this._ALM.on(AsyncListManagerEvent.UPDATED, (xhr) => {
      this._injectData(xhr);
    });
  }

  manageFilters(filter) {
    const dataHolder = filter.parentNode.children[0];

    this.opts = {
      ...this.opts,
      filters: Number(dataHolder.dataset.id),
    };

    this._ALM.setOpts(this.opts);
    this._ALM.load(this.opts);
  }

  _clickLoadMore(event) {
    this._ALM.loadMore(event);
  }

  _injectData(xhr) {
    if (xhr.data.has_results == false) {
      this._toggleNoResult();
      if (this.dom.loadMore) {
        this.dom.loadMore.classList.remove("show");
      }
    } else {
      //manage load more
      if (this.dom.loadMore) {
        if (xhr.data.load_more == true) this.dom.loadMore.classList.add("show");
        else this.dom.loadMore.classList.remove("show");
      }

      // Insert dom
      if (xhr.data.page == 1) {
        this.dom.listCards.innerHTML = xhr.data.posts_html;
      } else {
        this.dom.listCards.insertAdjacentHTML("beforeend", xhr.data.posts_html);
      }

      //Animation at insert
      this.dom.cards = this.dom.listCards.querySelectorAll(".to-animate");
      for (let i = 0; i < this.dom.cards.length; i++) {
        let delay = (i + 1) * 200;
        this.animateCard(i, delay);
      }
    }
  }
  _searchProcess() {
    this.opts.searchValue = this.dom.inputSearch.value;

    this._ALM.load(this.opts);
  }

  _resetSearch() {
    this.dom.inputSearch.value = "";
    this.opts.searchValue = "";

    this._ALM.load(this.opts);
  }
  _closePopin() {
    this.dom.popinFilters.classList.remove("show");
  }

  _managePopin() {
    this.dom.popinFilters.classList.add("show");
  }

  _toggleNoResult() {
    this.dom.component.classList.add("no-results");
    this.dom.listCards.innerHTML = "";
  }

  animateCard(i, delay) {
    setTimeout(() => {
      this.dom.cards[i].classList.remove("to-animate");
    }, delay);
  }
}
