import Component from "../component";

export default class LargeVideo extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }
  _retrieveDOM() {
    this.dom.wrapper = this.dom.component.querySelector(".js-wrapper");
    this.dom.poster = this.dom.component.querySelector(".js-poster");
    this.dom.video = this.dom.component.querySelector(".js-video");
  }

  init() {
    this.dom.wrapper.addEventListener("click", () => {
      this.dom.wrapper.classList.add("playing");

      if (this.dom.video) {
        this.dom.video.play();
      }
    });
  }
  /*

    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
