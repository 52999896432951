import Page from "../page";

export default class Candidacy extends Page {
  constructor(...args) {
    super(...args);

    this.isHubspot = this.dom.page.classList.contains("is-hubspot");

    if (this.isHubspot) return;
    this._retrieveDOM();
    this._initTls();
    this.link = "";
  }
  _retrieveDOM() {
    this.dom.inputFormation = this.dom.page.querySelectorAll(
      ".js-input-formation",
    );
    this.dom.inputCampus = this.dom.page.querySelectorAll(".js-input-campus");
    this.dom.form = this.dom.page.querySelector(".js-form");
    this.dom.btn = this.dom.page.querySelector(".js-nav");
  }

  uncheckCampus = () => {
    for (let i = 0; i < this.dom.inputCampus.length; i++) {
      let radio = this.dom.inputCampus[i].querySelector("input");
      radio.checked = false;
    }
  };

  init() {
    super.init();
    if (this.isHubspot) return;

    for (let i = 0; i < this.dom.inputFormation.length; i++) {
      this.dom.inputFormation[i].addEventListener("click", () => {
        this.dom.form.dataset.choice = i + 1;
        this.link = null;
        this.dom.btn.classList.add("disabled");
        this.uncheckCampus();
      });
    }

    for (let i = 0; i < this.dom.inputCampus.length; i++) {
      this.dom.inputCampus[i].addEventListener("click", () => {
        this.link = this.dom.inputCampus[i].dataset.link;
        this.dom.btn.classList.remove("disabled");
      });
    }

    this.dom.btn.addEventListener("click", () => {
      if (this.link !== "") {
        window.open(this.link, "_blank");
      }
    });
  }
  /**
   * init() {}
   * bind() {}
   * unbind() {}
   * resize(width, height) {}
   * _destroy() {}
   */
}
