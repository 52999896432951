import Component from "../component";
import DropdownManager from "../../helpers/dropdown";

export default class Dropdown extends Component {
  constructor(...args) {
    super(...args);
  }

  init() {
    super.init();
    const open = this.dom.component.dataset.open;
    this.DropdownManager = new DropdownManager({
      initOpen: open ? true : false,
      domComponent: this.dom.component,
    });
  }
}
