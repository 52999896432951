import "../../sass/main.generic.scss";
import "../lib/lib";
import "../lib/polyfill";
import debounce from "lodash.debounce";
import { gsap } from "../gsap";
import { ScrambleTextPlugin } from "../gsap/ScrambleTextPlugin";
import { PageEvent } from "../pages/page";
import { map } from "../map/map.generic";
import States from "../helpers/states";
import Store from "../helpers/Store";
import Router from "../helpers/Router";
import LocalSvgSprite from "../helpers/svg-sprite";
import Google from "../helpers/google";

gsap.registerPlugin(ScrambleTextPlugin);

if (window.ENV.browser === "ie" || window.ENV.browser === "edge") {
  var oldGetBoundingClientRect = HTMLElement.prototype.getBoundingClientRect;
  HTMLElement.prototype.getBoundingClientRect = function () {
    var res = oldGetBoundingClientRect.apply(this, arguments);
    res.x = res.left;
    res.y = res.top;
    return res;
  };
}

export default class Main {
  constructor() {
    var localSvgSprite = new LocalSvgSprite();

    this._device = "generic";
    this._map = map;
    Store.componentsMap = map;

    this.Components = {};
    this.Page = null;

    this._cache = {};
    this._DOMparser = new DOMParser();
    this._isFirstInit = true;

    bindAll(this, [
      "_resize",
      // "_routeChangeHandler",
      // "_loadingHandler",
      // "_onLoadingProgress",
      "_mediaLoadedHandler",
      "_pageLoadedHandler",
      "_destroyClasses",
      // "_request",
      // "_response",
    ]);

    this._retrieveDom();
    this._bind();
    this._initContext();

    this._dom.body.classList.add("dom-content-loaded");
  }

  /**
   * Retrieve DOM Elements
   */
  _retrieveDom() {
    this._dom = {
      body: document.body,
      main: document.querySelector("body main"),
      screenwrapper: document.querySelector("body main .screen-wrapper"),
      page: document.querySelector("body main .page"),
      transition: document.querySelector("body .screen-transition"),
      appWrapper: document.body.querySelector(".app-wrapper"),
      scrollWrapper: document.body.querySelector(".scroll-wrapper"),
      textLoading: document.body.querySelector(".js-screen-transition-txt"),
      transitionBar: document.body.querySelector(".js-screen-transition-bar"),
      transitionProgress: document.body.querySelector(
        ".js-screen-transition-progress",
      ),
    };

    States.dom = this._dom;
  }

  /**
   * Bind events
   */
  _bind() {
    window.addEventListener("resize", debounce(this._resize, 200));
  }

  /**
   * Initialise the current context
   */
  _initContext() {
    this._retrieveDomProperties();
    this._initComponents();
    this._initPage();

    gsap.killTweensOf(this._dom.transitionProgress);
    gsap.set(this._dom.transitionProgress, { scaleX: 0 });

    this._pageLoadedHandler();
    this._resize();

    setTimeout(() => {
      this._resize();
    }, 2000);

    Google.bind();
  }

  /**
   * Retrieve page properties: entity & page name
   */
  _retrieveDomProperties() {
    this._properties = {
      entity: this._dom.page.getAttribute("data-entity").toLowerCase(),
      page: this._dom.page.getAttribute("data-page").toLowerCase(),
    };
  }

  /**
   * Detect available components in Page and instanciate them
   */
  _initComponents() {
    const container = this._isFirstInit ? this._dom.body : this._dom.page;
    const components = container.querySelectorAll("[data-component]");
    for (let i = 0, j = components.length; i < j; i++) {
      const component = components[i];
      const name = component.getAttribute("data-component");
      if (this._map.components.hasOwnProperty(name)) {
        if (this.Components.hasOwnProperty(name.capitalize())) {
          if (isArray(this.Components[name.capitalize()])) {
            this.Components[name.capitalize()].push(
              new this._map.components[name](component),
            );
          } else {
            this.Components[name.capitalize()] = [
              this.Components[name.capitalize()],
              new this._map.components[name](component),
            ];
          }
        } else {
          this.Components[name.capitalize()] = new this._map.components[name](
            component,
          );
        }
      } else {
        throw new Error(
          `Main${this._device.capitalize()} :: you are calling non-existing '${name}' component in your map file. Please check your map file.`,
        );
      }
    }

    States.Components = this.Components;
  }

  /**
   * Detect current page, find class and instanciate it
   */
  _initPage() {
    if (this._map.pages.hasOwnProperty(this._properties.entity)) {
      if (
        this._map.pages[this._properties.entity].hasOwnProperty(
          this._properties.page,
        )
      ) {
        this.Page = new this._map.pages[this._properties.entity][
          this._properties.page
        ](this._dom.page, this.Components);
      } else {
        throw new Error(
          `Main${this._device.capitalize()} :: you are calling non-existing '${
            this._properties.page
          }' page for entity '${
            this._properties.page
          }' in your map file. Please check your map file.`,
        );
      }
    } else {
      throw new Error(
        `Main${this._device.capitalize()} :: you are calling non-existing '${
          this._properties.entity
        }' page entity in your map file. Please check your map file.`,
      );
    }

    States.Page = this.Page;
  }

  /**
   * Triggered when the window is resize
   */
  _resize() {
    const width = window.innerWidth || document.documentElement.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight;

    this._browseComponents("_resize", [width, height]);
    this._browseComponents("resize", [width, height]);

    if (this.Page) this.Page.resize(width, height);

    if (Store.isMobile) {
      clearTimeout(this._resizeTo);
      this._resizeTo = setTimeout(() => {
        const width = window.innerWidth || document.documentElement.clientWidth;
        const height =
          window.innerHeight || document.documentElement.clientHeight;

        this._browseComponents("_resize", [width, height]);
        this._browseComponents("resize", [width, height]);

        if (this.Page) this.Page.resize(width, height);
      }, 100);
    }

    States.width = width;
    States.height = height;
  }

  /**
   * Triggered when the media are loaded
   */
  _mediaLoadedHandler() {
    this._browseComponents("mediaLoadedHandler");

    this.Page.mediaLoadedHandler();
  }

  /**
   * Triggered when the page is loaded
   */
  _pageLoadedHandler() {
    this._browseComponents("mediaLoadedHandler");
    this._browseComponents("init");
    this._browseComponents("bind");

    this.Page.init();
    this.Page.bind();
    this.Page.show();

    // CLEAN
    gsap.to(this._dom.transition, {
      duration: 0.7,
      y: "-100vh",
      ease: "power2.inOut",
      onComplete: () => {
        // this.tlLoading.pause();
      },
    });

    Router.execBodyScripts(this._dom.page);
    Router.updateSwitchLangUrl();
    Router.unlock();
    Router.listen();
  }

  /**
   * Destroy instances of the previous Page / Components
   */
  _destroyClasses() {
    this._dom.screenwrapper.removeChild(this.PreviousPage.dom.page);

    this.PreviousPage.off(PageEvent.PAGE_DESTROYED, this._destroyClasses);
    this.PreviousPage = null;
  }

  _browseComponents(methodName, argsArray = [], callback = null) {
    for (let key in this.Components) {
      if (this.Components.hasOwnProperty(key)) {
        // Browse all components
        let component = null;
        if (isArray(this.Components[key])) {
          for (let i = 0, j = this.Components[key].length; i < j; i++) {
            const componentsType = this.Components[key];
            component = componentsType[i];
            if (
              component.persist === false ||
              ((methodName !== "init" || this._isFirstInit === true) &&
                methodName !== "unbind")
            )
              component[methodName].apply(component, argsArray);
          }
        } else {
          component = this.Components[key];
          if (
            component.persist === false ||
            ((methodName !== "init" || this._isFirstInit === true) &&
              methodName !== "unbind")
          )
            component[methodName].apply(component, argsArray);
        }

        // Callback to call
        if (typeof callback === "function")
          callback.call(callback, component, key);
      }
    }

    if (methodName === "init") this._isFirstInit = false;
  }
}

const __dcl = () => {
  new Main();
};

window.addEventListener("load", __dcl);
