import Component from "../component";
import { gsap } from "../../gsap";
import { ScrollTrigger } from "../../gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class HeroHome extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.header = this.dom.component;
  }

  bind() {
    gsap.from(this.header, {
      borderRadius: "0px",
      ease: "easeIn",
      duration: 0.4,
      scrollTrigger: {
        trigger: this.header,
        start: "bottom bottom",
        end: "bottom center",
        scrub: 1,
        onLeave: (self) => self.kill(false, true),
      },
    });
  }
}
