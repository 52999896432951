import Component from "../component";
import { gsap } from "../../gsap";

export default class CustomCursor extends Component {
  position = { x: 0, y: 0 };
  scale = 0;
  radius = 116;
  timingCursor = 0.4;
  timingText = 0.5;
  videoPlaying = false;
  hovering = false;

  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.cursor = this.dom.component;
    this.textRegister = this.dom.component.querySelector(".register");
    this.textSlide = this.dom.component.querySelector(".slide");
    this.textPlay = this.dom.component.querySelector(".play");

    this.cardsRegister = document.querySelectorAll("[data-cursor-register]");
    this.cardsSlide = document.querySelectorAll("[data-cursor-slide]");
    this.cardsPlay = document.querySelectorAll("[data-cursor-play]");
  }

  updatePosition({ clientX, clientY }) {
    this.position = { x: clientX, y: clientY };
    this.updateCursor();
  }

  updateCursor() {
    gsap.to(this.cursor, {
      x: this.position.x,
      y: this.position.y,
      scale: this.scale,
      duration: this.timingCursor,
      ease: "power2",
    });
  }

  hideCursor(type) {
    if (type == "register") this.toggleText(this.textRegister, 0);
    if (type == "slide") this.toggleText(this.textSlide, 0);
    if (type == "play") this.toggleText(this.textPlay, 0);

    this.scale = 0;
    this.updateCursor();
  }

  showCursor(type) {
    if (this.videoPlaying && type == "play") return;
    if (type == "register") this.toggleText(this.textRegister, 1);
    if (type == "slide") this.toggleText(this.textSlide, 1);
    if (type == "play") this.toggleText(this.textPlay, 1);

    this.scale = 1;
    this.updateCursor();
  }

  toggleText = (el, value) => {
    gsap.to(el, {
      opacity: value,
      duration: this.timingText,
      ease: "power2",
    });
  };

  toggleCursorPlay = () => {
    if (!this.hovering) return;
    if (!this.videoPlaying) {
      this.hideCursor("play");
      this.videoPlaying = true;
      return;
    }

    if (this.videoPlaying) {
      this.videoPlaying = false;
      this.showCursor("play");
      return;
    }
  };

  showVideoControls = (card) => {
    const video = card.querySelector("video");
    video.controls = true;
  };

  attachCardEvent(cards, type) {
    if (cards.length >= 1) {
      cards.forEach((card) => {
        card.addEventListener("mouseenter", () => {
          if (type == "play") this.hovering = true;
          this.showCursor(type);
        });

        card.addEventListener("mouseleave", () => {
          if (type == "play") this.hovering = false;
          this.hideCursor(type);
        });

        if (type === "play")
          card.addEventListener("click", () => {
            this.toggleCursorPlay();
            this.showVideoControls(card);
          });
      });
    }
  }

  bind() {
    this.radius = this.cursor.getBoundingClientRect().width;
    document.addEventListener("pointermove", (e) => this.updatePosition(e));
    document.addEventListener("keyup", (event) => {
      if (event.code === "Space") this.toggleCursorPlay();
    });

    this.attachCardEvent(this.cardsRegister, "register");
    this.attachCardEvent(this.cardsSlide, "slide");
    this.attachCardEvent(this.cardsPlay, "play");
  }
}
