class Request {
    constructor() {
        this._s = [];
    }

    AJAX(opts) {

        // Set callback
        if (opts.success) {
            this.callback = opts.success;
        }

        // Build params
        const data = {'data' : opts.data};
        this._s = [];
        const params = this.formatParams(data);


        const request = new XMLHttpRequest();
        request.open('POST', opts.url, true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        request.send(params);
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
                const xhr = JSON.parse(request.response);
                if (this.callback) {
                    this.callback.call(this.callback, xhr);
                }
            }
        };

    }

    reset()
    {
        this._s = [];
    }

    formatParams (a)
    {
        var prefix, add, name, r20, output;
        r20 = /%20/g;

        if (a instanceof Array) {
            for (name in a) {
                this.add(name, a[name]);
            }
        } else {
            for (prefix in a) {
                this.buildParams(prefix, a[ prefix ]);
            }
        }
        output = this._s.join("&").replace(r20, "+");
        return output;
    }

    add(key, value)
    {
        value = ( typeof value == 'function' ) ? value() : ( value == null ? "" : value );
        this._s[ this._s.length ] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
    }

    buildParams(prefix, obj)
    {
        var name, i, l, rbracket;
        rbracket = /\[\]$/;
        if (obj instanceof Array) {
            for (i = 0, l = obj.length; i < l; i++) {
                if (rbracket.test(prefix)) {
                    this.add(prefix, obj[i]);
                } else {
                    this.buildParams(prefix + "[" + ( typeof obj[i] === "object" ? i : "" ) + "]", obj[i]);
                }
            }
        } else if (typeof obj == "object") {
            // Serialize object item.
            for (name in obj) {
                this.buildParams(prefix + "[" + name + "]", obj[ name ]);
            }
        } else {
            // Serialize scalar item.
            this.add(prefix, obj);
        }
    }

}
export default new Request();
