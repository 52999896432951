import States from "../helpers/states";
import Store from "../helpers/Store";
import { gsap } from "../gsap";
import { ScrollTrigger } from "../gsap/ScrollTrigger";
import { SplitText } from "../gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);

import Component from "../components/component";

export const PageEvent = {
  NEXT: "__pe_next",
  PAGE_DESTROYED: "__pe_page_destroyed",
};

export default class Page extends Component {
  _scrollValue = {
    current: null,
    prev: null,
  };

  constructor(page, components) {
    super();

    this.dom = {
      component: page,
      page,
      main: this._find("main"),
      scrollable: this._find(".scrollable"),
      container: this._find(".container"),
      scrollSections: this._findAll(".js-scroll-section"),
    };

    this.Components = components;

    if (Store.Scrollbar) Store.Scrollbar.locked = false;

    bindAll(this, ["_update", "_scrollHandler", "_next", "_destroy"]);

    this._showTl = gsap.timeline({ paused: true });
    this._hideTl = gsap.timeline({
      paused: true,
      onStart: () => {
        this.dom.page.style.position = "absolute";
        this.dom.page.style.top = -getWindowPosition() + "px";

        // Reset scroll
        document.scrollingElement.scrollTop =
          document.scrollingElement.scrollLeft =
          document.body.scrollTop =
          document.body.scrollLeft =
          document.documentElement.scrollTop =
          document.documentElement.scrollLeft =
          window.scrollTop =
          window.scrollLeft =
            0;
      },
      onComplete: this._destroy,
    });
  }

  _retrieveDOM() {}

  _initTls() {
    this._showTl.from(this.dom.page, { autoAlpha: 0, duration: 0 }, 0);

    this._hideTl.to(this.dom.page, { autoAlpha: 0, duration: 0 }, 0).add(() => {
      this._next();
    }, 0);
  }

  init() {
    console.log("init page");

    this._getAndBindAnimatedTitle("data-animated-title");
    this._getAndBindAnimatedColoredText("data-animated-colored-text");
    this._getAndBindAnimatedButton("data-animated-button");
    this._getAndBindAnimatedScale("data-animated-scale");
  }

  bind() {
    // RafManager.on(this._update);
    // if (this.scroll)
    //     this.scroll.on(this._scrollHandler);

    ["_scroll"].forEach((fn) => (this[fn] = this[fn].bind(this)));

    window.addEventListener("scroll", this._scroll, { passive: true });
  }

  unbind() {
    window.removeEventListener("scroll", this._scroll);
  }

  mediaLoadedHandler() {
    console.log("PAGE.JS : mediaLoadedHandler");
    this.mediaLoaded = true;
  }

  show() {
    this._showTl.play();
  }

  hide() {
    this._showTl.kill();
    this._hideTl.play();
  }

  _update(delta, time) {}

  _scrollHandler(currentScroll) {}

  _scroll() {
    this._scrollValue.current = window.scrollY;

    // Example
    // if( this.Components.hasOwnProperty( 'Menu' ) ) {
    //     this.Components[ 'Menu' ]._scroll( this._scrollValue.current );
    // }
  }

  resize(width = States.width, height = States.height) {
    // if (this.scroll)
    //     this.scroll.resize(width, height);
    // if (this.webGLCanvas)
    //     this.webGLCanvas.resize(width, height);
  }

  _next() {
    this.emit(PageEvent.NEXT);
  }

  _destroy() {
    this._next();
    this.emit(PageEvent.PAGE_DESTROYED);
  }

  _getPersitance() {
    return false;
  }

  _getAndBindAnimatedTitle(data_name) {
    const titles = this.dom.page.querySelectorAll(`[${data_name}]`);

    if (titles) {
      titles.forEach((title) => {
        let splitText = new SplitText(title, {
          tag: "span",
          type: "words",
          wordsClass: "animated-title-line-words",
        });

        let splitTextWrapperLine = new SplitText(title, {
          tag: "span",
          type: "lines",
          linesClass: "animated-title-line-lines",
        });

        gsap.to(title, {
          opacity: 1,
          duration: 0.1,
          ease: "power2",
          scrollTrigger: {
            trigger: title,
            start: "top bottom",
            end: "bottom top",
          },
        });

        gsap.from(splitText.words, {
          duration: 1,
          y: 110,
          rotate: 5,
          ease: "power2",
          stagger: 0.05,
          scrollTrigger: {
            trigger: title,
            start: "top bottom",
            end: "bottom top",
          },
        });
      });
    }
  }

  _getAndBindAnimatedColoredText(data_name) {
    const texts = this.dom.page.querySelectorAll(`[${data_name}]`);

    if (texts) {
      texts.forEach((text) => {
        let splitText = new SplitText(text, {
          tag: "span",
          type: "chars",
          charsClass: "animated-text-line-chars",
        });

        gsap.to(splitText.chars, {
          color: "#000",
          ease: "power2",
          stagger: 0.7,
          scrollTrigger: {
            trigger: text,
            start: "top 80%",
            end: "bottom 40%",
            scrub: 1,
          },
        });
      });
    }
  }

  _getAndBindAnimatedButton(data_name) {
    const buttons = this.dom.page.querySelectorAll(`[${data_name}]`);

    if (buttons) {
      buttons.forEach((button) => {
        if (window.innerWidth <= 768) {
          button.classList.add("animated-mobile");
        }
        let txt = button.querySelector(".text_wrapper");

        gsap.from(txt, {
          width: 0,
          marginRight: 0,
          ease: "power2",
          duration: 0.65,
          delay: 0.2,
          scrollTrigger: {
            trigger: button,
            start: "top 90%",
            end: "bottom 10%",
          },
        });

        gsap.to(txt, {
          opacity: 1,
          ease: "power2",
          duration: 0.5,
          delay: 0.9,
        });

        gsap.to(button, {
          opacity: 1,
          ease: "power2",
          duration: 0.8,
        });
      });
    }
  }

  _getAndBindAnimatedScale(data_name) {
    const wrappers = this.dom.page.querySelectorAll(`[${data_name}]`);

    if (wrappers) {
      wrappers.forEach((wrapper) => {
        let txt = wrapper.querySelector(".title");

        gsap.from(wrapper, {
          scaleX: 0.6,
          scaleY: 0.6,
          ease: "easeIn",
          duration: 0.4,
          //stagger: 0.7,
          onComplete: () => {
            if (txt) {
              gsap.to(txt, {
                opacity: 1,
                ease: "power2",
                duration: 0.3,
              });
            }
          },
          scrollTrigger: {
            trigger: wrapper,
            start: "top 90%",
            end: "bottom 90%",
            scrub: 1,
            onLeave: (self) => self.kill(false, true),
          },
        });
      });
    }
  }
}
