import Component from "../component";

export default class BannerInscription extends Component {
  constructor(...args) {
    super(...args);
  }

  init() {
    let selectedDate = new Date(this.dom.component.dataset.date);
    let now = new Date();
    now.setHours(0, 0, 0, 0);

    if (selectedDate.getTime() > now.getTime()) {
      this.dom.component.dataset.status = "progress";
    } else {
      this.dom.component.dataset.status = "end";
    }
  }

  /*

    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
