import Component from "../component";
import { gsap } from "../../gsap";

export default class FixedNavigation extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.anchors = this.dom.component.querySelectorAll(".fixed");

    this.markers = document.body.querySelector(".ct--CPTInfosBar");
    if (!this.markers)
      this.markers = document.body.querySelector(".ct--Flexible");
  }

  bind() {
    if (this.markers && this.anchors) {
      gsap.fromTo(
        this.anchors,
        { y: "-150svh", pointerEvents: "none", userSelect: "none" },
        {
          duration: 0.7,
          y: 0,
          pointerEvents: "all",
          userSelect: "auto",
          ease: "Expo.easeInOut",
          scrollTrigger: {
            trigger: this.markers,
            start: "top top",
            end: "top bottom",
            toggleActions: "play none none reverse",
          },
        },
      );
    }
  }
}
