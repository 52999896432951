import EventEmitter from 'eventemitter3';
import Request from "../lib/ajax/request";

/**
 * Manage async post list with load more
 */
export default class AsyncListManager extends EventEmitter
{
    constructor(action, loader = null)
    {

        super();
        this._page = 1;
        this._opts = {};
        this._loadingMore = false;
        this._action = action;

        this._loader = loader;

        this._dom = {};
    }

    _getDom()
    {
        this._dom.body = document.body;
    }

    _response(xhr)
    {
        setTimeout(() => {
            document.body.classList.remove('loading');
        }, 300);

        xhr.loadMore = this._loadingMore;
        if(xhr.data.hasOwnProperty('has_results')){
            if(xhr.data.has_results === false && this._loadingMore === false){
                this._opts = {};
                this._page = 1;
            }
            this.emit(AsyncListManagerEvent.UPDATED, xhr);
        }
    }

    loadMore(event)
    {
        if(event){
            if(event.hasOwnProperty('preventDefault'))
                event.preventDefault();
            event.returnValue = false;
        }

        document.body.classList.add('loading');

        this._loadingMore = true;
        this._page = this._page + 1;
        this._request();
    }

    load(opts, page = 1)
    {
        document.body.classList.add('loading');
        this._loadingMore = false;
        this._opts = null;
        this._page = page;
        this._opts = Object.assign({}, opts);
        this._request();
    }

    _request()
    {
        this._opts.page = this._page;
        Request.AJAX({
            'url': '/wp/wp-admin/admin-ajax.php?action=' + this._action,
            'data': this._opts,
            'success': this._response.bind(this)
        });
    }

    addExclusion(id)
    {
        this._opts.excludes = id;
    }

    setPage( page ) {
        this._page = page;
    }

    setOpts( opts ) {
        this._opts = Object.assign({}, opts);
    }
}

export const AsyncListManagerEvent = {
    'UPDATED' : '__alme_updated',
    'CLEAR_FILTERS' : '__alme_clear_events'
}
